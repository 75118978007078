import React from "react"
import classNames from "classnames"
import SEO from "../components/seo"
import Layout from "../components/Layout/layout"
import Projects from "../components/Sections/Projects/Projects"
import styles from "./contact.module.scss"

const contactPage = () => (
  <Layout mainClassName={styles.contact}>
    <SEO
      title="Contact"
      description="Selection of projects that I've worked on over the years."
    />
    <section className="animated fadeInDown">
      <div className={classNames(styles.contentWrapper, "row")}>
        <div className="col-md-5">
          <div className="title animated fadeInDown">
            <h2>Let's Talk</h2>
            <h3 className={styles.subtitle}>Looking for a freelance designer, grow your in-house design team,
or simply want to chat about design?</h3>
          </div>
        </div>
        <div className="col-md-6 offset-md-1">
          <form name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true" action="/contact">
            <input type="hidden" name="bot-field" />
            <label class="animated fadeInDown">
              <h4>Name</h4>
              <input type="text" name="name" id="name" placeholder="Your name please" required/>
            </label>
            <label class="animated fadeInDown">
              <h4>Email</h4>
              <input type="email" name="email" id="email" placeholder="Your email address" required/>
            </label>
            <label class="animated fadeInDown">
              <h4>Subject</h4>
              <input type="text" name="subject" id="subject" placeholder="What do you want to chat about?" required/>
            </label>
            <label class="animated fadeInDown">
              <h4>Message</h4>
              <textarea name="message" id="message" placeholder="A quick note" rows="5" required/>
            </label>
            {/* <button type="submit" className="animated fadeInDown">Send Message</button> */}
            <button type="submit" className="icon ion-ios-arrow-forward">
                Send Message
              </button>
          </form>
        </div>
      </div>
    </section>
    {/* <Projects sectionTitle="Projects" columns={6} /> */}
  </Layout>
)

export default contactPage
